<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--user">
      <div class="view-header">
        <div class="view-header__footer">
          <Btn
            class="back-btn"
            :to="{ name: 'user', params: { userid: userid } }"
            round
            grow
            icon="arrow-left"
          />
          <h1
            class="page-title"
            v-html="`Modifier ${data.prenom} ${data.nom}`"
          ></h1>
        </div>
      </div>

      <div class="view-body">
        <Section class="section--styleguide">
          <Container>
            <div class="grid">
              <div class="tmp-col">
                <InputImage
                  id="avatar"
                  type="avatar"
                  label="Photo de profil"
                  :avatarInitials="
                    helperService.userInitials(data.prenom, data.nom)
                  "
                  :avatarImage="data.avatar ?? null"
                  :avatarColor="data.couleur"
                  textInfo="Fichier au format JPEG et PNG, 1Mo maximum"
                  avatarSize="large"
                  @update:modelValue="setMedia($event)"
                />
              </div>
              <div class="tmp-col col-order-first">
                <Input id="prenom" label="Prénom" required />
                <Input id="nom" label="Nom" required />
                <KeyValue label="Identifiant" :value="data.identifiant" />
                <Input id="email" label="Email" required />
                <Input id="telportable" label="Téléphone" />
              </div>
              <div class="tmp-col col-order-first">
                <SelectExtended
                  id="entite"
                  search
                  required
                  label="Entité"
                  optionKey="id"
                  optionValue="nom"
                  apiEndpoint="entite"
                  :apiParams="{
                     sort: 'nom.ASC',
                  }"
                />
                <SelectExtended
                  id="profils"
                  multiple
                  search
                  required
                  chips
                  label="Profil de droits"
                  optionKey="uid"
                  optionValue="designation"
                  apiEndpoint="profil"
                />
                <Radio
                  id="actif"
                  required
                  :disabled="itsMe"
                  :items="[
                    { label: 'Oui', value: true },
                    { label: 'Non', value: false },
                  ]"
                  label="Actif"
                  inputStyle="inline"
                />
              </div>
            </div>
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn
            :to="{ name: 'user', params: { userid: userid } }"
            text="Annuler"
          />
          <Btn
            v-if="
              !itsMe &&
              this.helperService.userHasPermission(
                `utilisateur_remove_full,utilisateur_remove_entite`,
                'OR'
              )
            "
            hollow
            @click="modalDeleteUser = true"
            text="Supprimer"
          />
          <Btn
            v-if="
              this.helperService.userHasPermission(
                `utilisateur_edit_full,utilisateur_edit_entite`,
                'OR'
              )
            "
            btnType="submit"
            text="Modifier"
            color="primary"
          />
        </div>
      </div>
    </div>

    <!-- Modals -->
    <Modal
      title="Supprimer l'utilisateur&nbsp;?"
      :active="modalDeleteUser"
      :data="data"
      @modal-close="modalDeleteUser = false"
    >
      <template v-slot:modal-body="{ data }">
        <p>
          Voulez vous vraiment supprimer l'utiisateur
          <b>{{ data.prenom }} {{ data.nom }}</b
          >?
        </p>
      </template>
      <template v-slot:modal-footer>
        <Btn text="Annuler" @click="modalDeleteUser = false" />
        <Btn
          text="Supprimer"
          @click="deleteUser()"
          icon="trash-alt"
          c
          olor="primary"
        />
      </template>
    </Modal>
  </form>
</template>

<script>
import KeyValue from '@/components/form/KeyValue.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import InputImage from '@/components/form/InputImage.vue'
import Btn from '@/components/base/Btn.vue'
import Modal from '@/components/layout/Modal.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'UserEditView',
  components: {
    KeyValue,
    Input,
    SelectExtended,
    InputImage,
    Radio,
    Btn,
    Modal,
    Section,
    Container,
  },

  data() {
    // Define a validation schema
    const validationSchema = this.yup.object().shape({
      nom: this.yup.string().required(),
      entite: this.yup.object().required(),
      profils: this.yup.array().required(),
      prenom: this.yup.string().required(),
      email: this.yup.string().email().required(),
      telportable: this.yup.string().nullable(),
      actif: this.yup.boolean().required(),
      avatar: this.yup.object().nullable(),
    })

    const { errors, isSubmitting } = this.formService.initFrom(validationSchema)
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleValues(values)
    })

    return {
      data: [],
      userid: null,
      onSubmit,
      errors,
      isSubmitting,
      modalDeleteUser: false,
      itsMe: false,
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  created() {
    this.userid = Number(this.$route.params.userid)
    this.getData()
    this.itsMe = this.userid === this.$store.state.auth.user.data.id
  },

  methods: {
    setMedia(event) {
      this.data.avatar = event
    },
    handleValues(values) {
      this.emitter.emit('open-loader')
      // eslint-disable-next-line no-param-reassign
      values.entite_id = values.entite.key
      // eslint-disable-next-line no-param-reassign
      values.avatar_id = values.avatar?.id || this.data?.avatar?.id

      this.fetchService.put(`utilisateur/${this.userid}`, values).then(
        (response) => {
          // if user is created then associate profil
          const profils = values.profils.map((profil) => ({ uid: profil.key }))

          this.fetchService
            .put(`utilisateur/${response.data.id}/profil`, { profils })
            .then(
              () => {
                // if all ok send alert and go to liste ?
                this.formService.resetForm()
                this.getData()
                this.emitter.emit('alert', {
                  type: 'success',
                  content: "L'utilisateur a bien été mis à jour.",
                })
              },
              (responseError) => {
                this.formService.handleApiError(responseError)
                this.emitter.emit('close-loader')
              },
            )
        },
        (responseError) => {
          this.formService.handleApiError(responseError)
          this.emitter.emit('close-loader')
        },
      )
      this.emitter.emit('close-loader')
    },
    getData() {
      // get data from API
      this.emitter.emit('open-loader')
      this.fetchService
        .get(`utilisateur/${this.userid}`, {})
        .then((response) => {
          this.data = response.data

          // Populate schema with data
          this.schema = this.formService.populateShema(this.data)

          // Set some specific data from user
          this.schema.entite = {
            key: this.data.entite.id,
            value: this.data.entite.nom,
          }
          this.schema.profils = this.data.profils.map((profil) => {
            const rObj = {}
            rObj.key = profil.uid
            rObj.value = profil.designation
            return rObj
          })

          // set form values
          this.formService.setFormValues(this.schema)
          this.emitter.emit('close-loader')
        })
    },
    deleteUser() {
      this.emitter.emit('open-loader')
      this.fetchService.delete(`utilisateur/${this.userid}`).then(
        () => {
          this.emitter.emit('alert', {
            type: 'success',
            content: "L'utilisateur a bien été supprimé.",
          })
          this.$router.push({ name: 'home' })
          this.emitter.emit('close-loader')
        },
        (responseError) => {
          this.emitter.emit('alert', {
            type: 'error',
            content: responseError.data,
          })
          this.emitter.emit('close-loader')
        },
      )
      this.modalDeleteUser = false
    },
  },
}
</script>
